
import GeneralData from '../components/GeneralData.vue'
import useProvider from "@/modules/providers/composables/useProvider";
import toastr from "toastr";
import {onMounted, ref} from "vue";
import router from "@/router";

export default {
    components: {GeneralData},
    props: ['id'],
    setup(props: { id: string }) {
        const {clearForm, providerForm, updateProvider, catalogs, getProvider} = useProvider()
        const sending = ref(false)
        const loading = ref(true)

        clearForm();

        onMounted(async () => {
            providerForm.value = await getProvider(props.id)
            loading.value = false
        })

        return {
            sending,
            loading,
            catalogs,
            cancel: () => router.push({name: 'providers'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await updateProvider(providerForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'providers'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
